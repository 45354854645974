@import url("https://fonts.googleapis.com/css?family=Lora:400,700|Libre+Barcode+128+Text");
@import url("https://fonts.googleapis.com/css?family=Shadows+Into+Light|Valero|Oswald|Aleo|Lato");


/* scroll button distance between the info section */
.scrollSection {
  padding-top: 30px;
}
.scrollSection p {
  color: black;
  font-size: 20px;
  -webkit-animation: scrollText 4s infinite;
  animation: scrollText 4s infinite;
}
/* scroll button item */
#scrollButton span {
  position: absolute;
  padding-top: 30px;
  left: 50%;
  width: 30px;
  height: 50px;
  margin-left: -15px;
  border: 2px solid black;
  border-radius: 50px;
  box-sizing: border-box;
  -webkit-animation: sdb03 4s infinite;
  animation: sdb03 4s infinite;
}
/* set the dot in the middle of the icon to 10px from top */
#scrollButton span::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: "";
  width: 6px;
  height: 6px;
  margin-left: -3px;
  opacity: 0;
  background-color: black;
  border-radius: 100%;
  box-sizing: border-box;
  -webkit-animation: sdb03 4s infinite;
  animation: sdb03 4s infinite;
}
@-webkit-keyframes sdb03 {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 0.5;
  }
  60% {
    box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
@keyframes sdb03 {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 0.5;
  }
  60% {
    box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes scrollText {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scrollText {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.aboutMe h1 {
  font-family: "Lato", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 400;
}
.aboutMe {
  background: white;
  color: black;
  padding: 10vh;
  font-size: 1em;
}
.skill h3 {
  font-family: "Shadows Into Light", cursive;
  font-size: 4rem;
  color: #766f57;
}
p {
  font-family: "Lato", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 100;
}
.td {
  padding-left: 0px !important;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.navbar {
  height: 6vh;
  font-family: "Lato", Helvetica, Arial, Lucida, sans-serif;
}
.navbar a:hover {
  padding: 5px;
  text-decoration: underline;
}
.navbar-collapse {
  background: white;
}
.navbarToggler {
  border: none;
}

.navbar-light {
  background-color: white;
}

.showcase:hover .landingPage {
  display: none;
}

.navbar-light {
  color: white;
}

.logo {
  height: 100px;
  width: 120px;
}
.scrollspy {
  position: relative;
  overflow-y: scroll;
}

.avatar {
  height: 100px;
  width: 100px;
  margin-bottom: 1vh;
}

.skill {
  background: white;
}

.aboutMe,
.skill {
  padding-bottom: 10vh;
}
/* fix ipad landscape */
@media (min-width: 1143px) {
  .avatar {
    height: 400px;
    width: 400px;
  }
}
@media (max-width: 1142px) {
  .avatar {
    height: 300px;
    width: 250px;
  }
}
@media (max-width: 768px) {
  .avatar {
    height: 180px;
    width: 180px;
  }
  .navbar {
    height: 8vh;
  }
}
@media (max-width: 375px) {
  .avatar {
    height: 200px;
    width: 180px;
  }
  .navbar {
    height: 9vh;
  }
}
@media (max-width: 375px) and (min-width: 812px) {
  .avatar {
    height: 200px;
    width: 180px;
  }
  .navbar {
    height: 9vh;
  }
}
@media (max-width: 320px) and (max-height: 600px) {
  .avatar {
    height: 200px;
    width: 170px;
  }
}

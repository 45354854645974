@import "src/style/default";

.introduction {
  max-width: 60%;
  .title {
    color: $text-color;
  }

  .cursor-blinking {
    color: $secondary-text-color;
    font-weight: 800;
    animation: blink 1s linear infinite;
  }
  .summary {
    font-size: 1rem;
  }
}

@import "src/style/default";

.navigation {
  bottom: 5rem;
  color: $secondary-text-color;
  position: fixed;
  right: 5%;

  a {
    color: $secondary-text-color;
    font-size: 0.9rem;
    &:hover {
      color: white;
    }
  }
}

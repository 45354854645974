$background-color: #020c1b;
$font-family: "Karla", sans-serif;
$button-background-color: #ecf0f3;
$text-color: #ebf2fa;
$secondary-text-color: #ffc300;
$slate-color: #8892b0;
$light-slate: #ccd6f6;
$box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
  6px 6px 10px rgba(0, 0, 0, 0.2);
$container-box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #d4e6f2;
$container-border-radius: 44px;
$container-margin: 2rem;
$container-padding: 3rem;

.default-button {
  background-color: $background-color;
  border-radius: 32px;
  border: 4px solid $secondary-text-color;
  box-sizing: border-box;
  color: $secondary-text-color;
  display: inline-block;
  font-weight: 600;
  height: 3rem;
  margin: 0 0.3em 0.3em 0;
  min-width: 10rem;
  padding: 0.5em 1.2em;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 1;
    box-shadow: inset -4px -4px 8px rgba(255, 255, 255, 0.5),
      inset 8px 8px 16px rgba(0, 0, 0, 0.1);
  }
}
.alternate-color {
  color: $secondary-text-color;
}

.slate-color {
  color: $slate-color;
}

.light-slate-color {
  color: $light-slate;
}
.icon-size {
  font-size: 2.2rem;
}
.card {
  background-color: #0a192f;
  border-radius: 12px;
  padding: 1rem;
  &:hover {
    background-color: #233554;
  }
}

@import "src/style/default";
body,
ul,
p {
  margin: 0;
  padding: 0;
  font-family: "Karla", serif;
  color: $text-color;
}

$font-family: "Karla", sans-serif;
blink {
  -webkit-animation-name: blink;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-animation-duration: 1s;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
h1 {
  font-size: 5.375rem;
}

h-100 {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}
button {
  background: none;
  border: none;
  color: $secondary-text-color;
  border-bottom: 1px solid $secondary-text-color;
}
